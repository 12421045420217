import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Image from "../components/Image";
import styles from "../styles/page.module.scss";

const About = () => {
  const data = useStaticQuery(graphql`
    query imageQuery {
      fileName: file(relativePath: { eq: "images/leap-into-void.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const srcInfo = (
    <>
      <a href="https://www.metmuseum.org/art/collection/search/266750">
        Leap into void
      </a>{" "}
      de Yves Klein
    </>
  );

  const productionList = [
    {
      authors: "GONÇALVES, Rafael",
      url: "https://nomeqqeivau0lieh.public.blob.vercel-storage.com/ESOCITE_ANAIS_2024-kF0NUD9qQpe0TkFRhiamN5yHYFdMw1.pdf",
      title:
        "Mediação algorítmica, viés de gênero e sexismo algorítmico",
      complement: "<em>Anais da XV Jornada Latino-Americana de Estudos Sociais da Ciência e Tecnologia (Esocite LA)</em>, Campinas, 2024.",
    },
    {
      authors: "GONÇALVES, Rafael; PADOVAN, Giordanno Oliveira",
      url: "https://anais.rba.abant.org.br/34rba/trabalho?trabalho=22896221",
      title:
        "Captura da virtualidade em algoritmos bioinspirados: apropriação da informação em práticas de modelagem computacional biomimética",
      complement: "<em>Anais da 34ª Reunião Brasileira de Antropologia (RBA)</em>, Belo Horizonte, 2024.",
    },
    {
      authors: "GONÇALVES, Rafael; PADOVAN, Giordanno Oliveira",
      url: "https://ocs.ige.unicamp.br/ojs/react/article/view/4035",
      title:
        "Coletivos maquínicos e sistemas digitais: Tensionamentos da noção de humanidade a partir de técnicas de aprendizado de máquina patenteadas pela Google",
      complement: "<em>Anais IX Reunião de Antropologia da Ciência e Tecnologia (ReACT)</em>, v. 6, n. 6, 2024.",
    },
    {
      authors: "GONÇALVES, Rafael",
      url: "http://doi.org/10.34024/2764859n.3",
      title:
        "Captura como fenômeno sociotécnico: apontamentos para uma caracterização a partir de tecnologias de inteligência artificial",
      complement: "<em>Revista Pimentalab</em>, v. 3 (Tecnoceno), p. 30-38, 2024.",
    },
    {
      authors: "GONÇALVES, Rafael",
      url: "https://doi.org/10.20396/ideias.v13i00.8668218",
      title:
        "Automatismo ontem e hoje: reflexões sobre os limites da inteligência artificial a partir de Simondon",
      complement: "<em>Ideias</em>, v. 13, e022008, 21 jun. 2022.",
    },
    {
      authors: "GONÇALVES, Rafael; FERREIRA, Pedro P",
      url: "https://reunioes.sbpcnet.org.br/74RA/inscritos/resumos/1392_16e072f0ac3379ec7ebf29ee4ddbced95.pdf",
      title:
        "Aprendizado de máquina como mediação técnica computacional: viés de gênero no processamento automático de textos relacionando pronomes e profissões pelo algoritmo BERT",
      complement:
        "<em>Anais [recurso eletrônico]: resumos da 74ª Reunião Anual da SBPC</em>, 24 jul. 2022.",
    },
    {
      authors: "GONÇALVES, Rafael; FERREIRA, Pedro P",
      url: "https://www.prp.unicamp.br/inscricao-congresso/resumos/2022P20272A33708O343.pdf",
      title:
        "Eficiência como viés algorítmico nas técnicas de aprendizado de máquina: caracterização baseada na produção tecnocientífica da Google",
      complement: "<em>XXX Congresso de Iniciação Científica da Unicamp</em>, 2022.",
    },
  ];

  return (
    <>
      <Helmet title="Sobre" />
      <Layout isReadingPage header="Sobre" className={styles.wrapper}>
        <div className={styles.content}>
          <section className={styles.about}>
            <Image
              srcInfo={srcInfo}
              className={styles.image}
              gatsbyImgProps={{ fluid: data.fileName.childImageSharp.fluid }}
            />
            <div className={styles.body}>
              <p>
                Criei esta página com a intenção de reunir meus estudos recentes em socioantropologia da tecnociência. É, assim, local de registro e experimentação de meu processo pessoal de pesquisa. Meus interesses também incluem: teoria crítica em seu sentido amplo; filosofia, sociologia e antropologia contemporâneas e estudos sociais da ciência e da tecnologia. Autores que me interessam em específico são Gilles Deleuze, Félix Guattari e{" "}
                <a
                  href="https://gregsifch.wordpress.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Gilbert Simondon
                </a>
                .
              </p>
              <p>
                Atualmente, sou mestrando em sociologia pelo IFCH/Unicamp e
                participo do{" "}
                <a
                  href="https://www.laspa.slg.br/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Laboratório da Sociologia dos Processos de Associação (LaSPA)
                </a>
                . Pesquiso sobre a modulação de comportamento operado por
                técnicas de aprendizado de máquina a partir da análise de
                patentes da Google. Possuo graduação em engenharia elétrica pela
                FEEC/Unicamp, onde pude estudar a área de aprendizado de
                máquina, tendo me dedicado em especial às redes neurais
                artificiais e ao aprendizado profundo.
              </p>
            </div>
          </section>
          <section className={styles.publications}>
            <header>
              <h2>Principais produções</h2>
            </header>
            <ul>
              {productionList.map(({ authors, title, complement, url }, i) => (
                <li key={i}>
                  {authors}{". "}
                  {url ? (
                    <a href={url} target="_blank" rel="noreferrer noopener">
                      {title}
                    </a>
                  ) : (
                    title
                  )}{". "}
                  <span dangerouslySetInnerHTML={{ __html: complement }} />
                </li>
              ))}
            </ul>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default About;
